<template>
  <div
    :draggable="draggable"
    class="opacity-25 timeline-module bg-gradient-to-br from-purple-500 via-purple-400 to-magenta-500"
    :class="[ { 'opacity-25': isDragging, 'opacity-100' : isActive, 'opacity-75' : !isActive, 'cursor-pointer' : !isLocked, 'cursor-not-allowed': isLocked }, moduleClass, borderStateClass ]"
    :style="baseModuleStyle"
    @dragstart="onDragStart"
    @dragend="onDragEnd"
    @click.prevent.stop="onClick"
  >
    <div class="flex justify-between w-full">
      <div class="flex items-center min-w-0">
        <button
          v-if="resizable"
          class="btn btn-resize"
          :class="{ 'bg-teal-500': showDebug }"
          style="cursor: col-resize;"
          @mousedown="onMouseDownLeft"
        >
          <fa-icon
            icon="grip-lines-vertical"
            class="text-white"
            size="lg"
          />
        </button>

        <div
          class="min-w-0 font-sans text-xs leading-loose truncate px-0.5"
          :class="{ 'italic capitalize' : !isValid, 'ml-2': !resizable }"
        >
          <fa-icon
            class="mr-1 text-white"
            size="sm"
            icon="shapes"
          />

          {{ label }}   <fa-icon
            v-if="!isValid"
            class="ml-1 text-red-500"
            size="sm"
            icon="triangle-exclamation"
          />  <span v-if="showDebug && draggable">(d)</span> <span v-if="showDebug && hasmoved">(m)</span>
        </div>
      </div>

      <button
        v-if="resizable"
        class="btn btn-resize"
        :class="{ 'bg-yellow-500': showDebug }"
        style="cursor: col-resize;"
        @mousedown="onMouseDownRight"
      >
        <fa-icon
          icon="grip-lines-vertical"
          class="text-white"
          size="lg"
        />
      </button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'TimelineModule',

  props: ['module', 'layer', 'scale', 'overallduration', 'showDebug', 'activeModuleId'],

  data () {
    return {
      minModuleDuration: 1,
      start_at: null,
      end_at: null,
      activedrag: true,

      isDragging: false,
      isResizing: false,

      hasmoved: false,
      activemode: null
    }
  },

  computed: {

    isActive () {
      return this.activeModuleId === this.module.id
    },

    draggable: {
      set (value) {
        this.activedrag = value
      },
      get () {
        return !this.isLocked && this.activedrag
      }
    },

    isSceneLayer () {
      return this.type === 'scene'
    },

    layerType () {
      if (this.isSceneLayer) return 'scene'
      return this.module.type
    },

    resizable () {
      return !this.isLocked
    },

    moduleClassBase () {
      let base = 'bg-purple'

      switch (this.layerType) {
        case 'scene':
          base = 'bg-orange'
          break
        case 'camera':
          base = 'bg-purple'
          break
        case 'attribute':
          base = 'bg-gray'
          break
        case 'prop':
          base = 'bg-teal'
          break
        case 'light':
          base = 'bg-yellow'
          break
        case 'comments':
          base = 'bg-teal'
          break
      }

      return base
    },

    moduleClass () {
      // if (!this.isValid) return this.invalidModuleClass
      return this.moduleClassBase + '-800'
    },

    // invalidModuleClass() {
    //   return this.moduleClassBase + '-300'
    // },

    baseModuleStyle () {
      return ' width: ' + this.duration * this.scale + 'px; left: ' + this.startAt * this.scale + 'px'
    },

    isValid () {
      if (!this.module.resource) return false
      if (!this.hasValidTarget) return false

      return true
    },

    hasValidResource () {
      if (this.module.resource !== null && this.module.resource !== undefined) return true
      return false
    },

    hasValidTarget () {
      return this.module.target.length > 0
    },

    isLocked () {
      return this.layer.locked === true
    },

    startAt () {
      if (this.start_at !== null) return this.start_at
      return this.module.start_at
    },

    endAt () {
      if (this.end_at !== null) return this.end_at
      return this.module.end_at
    },

    duration () {
      return this.endAt - this.startAt
    },

    borderStateClass () {
      let base = ''

      // if (!this.isValid) {
      //   base = 'border-red-800'
      // }

      if (this.isDragging) {
        base = 'border-blue-800'
      }

      if (this.isResizing) {
        base = 'border-purple-800'
      }

      if (this.isLocked) {
        base = 'border-blue-900'
      }

      if (this.isActive) {
        base = ' border-blue-200'
      }

      // ie. not any of the above, the default state
      if (base === '') {
        base = 'border-purple-900'
      }

      return base + ' border'
    },

    label () {
      // if (!this.hasValidTarget && !this.hasValidResource) return 'Missing: Asset, Mapping'
      // if (!this.hasValidTarget) return 'Missing: Mapping'
      // if (!this.hasValidResource) return 'Missing: Asset'

      return this.module.name
    }
  },

  watch: {
    isResizing () {
      this.attachWindowMouseUpListener()
    }
  },

  methods: {

    attachWindowMouseUpListener () {
      if (this.isResizing) {
        window.addEventListener('mouseup', this.onMouseUp)
      } else {
        window.removeEventListener('mouseup', this.onMouseUp)
      }
    },

    onDragStart (event) {
      if (this.isLocked) return

      // this.$emit('view-module', this.module.id)
      this.isDragging = true
      this.isResizing = false

      event.dataTransfer.setData('application/x-previz.timeline-module', this.module.id)
      event.dataTransfer.setData('application/x-previz.timeline-module-touchoffset', event.offsetX)
    },

    onDragEnd (event) {
      this.isDragging = false
      this.isResizing = false

      // console.log('drag_end event', event)
    },

    onClick (event) {
      if (this.isLocked) return
      // if (!this.hasmoved)
      this.$emit('view-module', this.module.id)
    },

    onMouseUp (event) {
      this.clearState()
    },

    onMouseDownLeft (event) {
      event.stopPropagation()
      if (this.isLocked) return

      // this.$emit('view-module', this.module.id)
      this.draggable = false
      this.isResizing = true

      this.$emit('mouse-down', (movementX) => {
        this.hasmoved = true
        movementX = movementX / this.scale

        this.$emit('drag-move-module-start', { mod: this.module, x: movementX })
      })
    },

    onMouseDownRight (event) {
      event.stopPropagation()
      if (this.isLocked) return

      // this.$emit('view-module', this.module.id)
      this.draggable = false
      this.isResizing = true

      this.$emit('mouse-down', (movementX) => {
        this.hasmoved = true
        movementX = movementX / this.scale

        this.$emit('drag-move-module-end', { mod: this.module, x: movementX })
      })
    },

    clearState () {
      setTimeout(() => {
        this.hasmoved = false
        this.isResizing = false
        this.isDragging = false

        this.active = false
        this.draggable = true
      }, 200)
    }
  }

}

</script>

<style scoped>
  .timeline-module-invalid {
    /* stripes: gray-300 / gray-500 */
    /*
    background: repeating-linear-gradient(
      45deg,
      #e2e8f0,
      #e2e8f0 10px,
      #a0aec0 10px,
      #a0aec0 20px
    );
    */
  }

  .timeline-module-invalid.bg-blue-500 {
    /* stripes blue-300 / blue-500 */
    /*
    background: repeating-linear-gradient(
      45deg,
      #90cdf4,
      #90cdf4 10px,
      #4299e1 10px,
      #4299e1 20px
    );
    */
  }

  .timeline-module-invalid.bg-orange-500 {
    /* stripes orange-300 / orange-500 */
    /*
    background: repeating-linear-gradient(
      -45deg,
      #fbd38d,
      #fbd38d 10px,
      #ed8936 10px,
      #ed8936 20px
    );
    */
  }

</style>
